<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }" class="">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="6" v-show="diller.length != 1">
            <b-form-group label="Dil">
              <v-select
                v-model="defaultDil"
                :options="diller"
                :reduce="(diller) => diller.lang"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider name="Site Başlığı" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Site Başlığı">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="site_baslik"
                  v-model="form.icerik[defaultDil].site_baslik"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider name="Site Seo Başlık" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Site Seo Başlık">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="site_seo_baslik"
                  v-model="form.icerik[defaultDil].site_seo_baslik"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider name="Description" :rules="{ required: true, max: 300 }" v-slot="validationContext">
              <b-form-group
                :label="`Description ( Açıklama ) ${onAciklamalength} / 160`"
                description="Description 160 karakteri geçmemelidir"
              >
                <b-textarea
                  size="lg"
                  class="rounded-0"
                  ref="description"
                  v-model="form.icerik[defaultDil].description"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="pt-3">
            <b-form-group label="Keywords ( Anahtar Kelimeler )">
              <b-form-tags
                size="lg"
                class="rounded-0 pt-2"
                ref="keywords"
                v-model="form.icerik[defaultDil].keywords"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider name="Author" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Author ( Yazar )">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="author"
                  v-model="form.author"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex">
              <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import vSelect from 'vue-select';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    vSelect,
  },
  setup(_, context) {
    const expo = {};
    expo.show = ref(false);
    const toast = useToast();

    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.form = ref({
      k_no: null,
      icerik: {},
      author: null,
    });

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          site_baslik: null,
          site_seo_baslik: null,
          description: null,
          keywords: [],
        };
      });
    };

    createIcerikDilUret();

    expo.onAciklamalength = computed(() => {
      return expo.form.value.description != null ? expo.form.value.description.length : 0;
    });

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('seoGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('seoEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
